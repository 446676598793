















































































import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'Maker',
  data () {
    return {
      headers: [
        { text: '会社名', value: 'name' },
        { text: 'Webサイト', value: 'url' },
        { text: '', value: 'id' }
      ],
      domesticDataList: [],
      foreignDataList: []
    }
  },
  components: {
  },
  mounted: async function () {
    try {
      const response = await axios.get('/v1/company.php')
      this.domesticDataList = response.data.domestic_data
      this.foreignDataList = response.data.foreign_data
    } catch (e) {
      console.log('海外取引企業の取得に失敗：', this.foreignDataList)
      console.error(e)
    }
  }
})
