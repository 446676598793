var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-6",attrs:{"no-gutters":"","padless":""}},[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('h3',{staticClass:"blue--text text--darken-4"},[_vm._v("取引メーカー")])]),_c('v-card-text',[_vm._v("東京アドコムは、国内外に多くのネットワークを持ち、スピーディーにお客様にご提供しております。")])],1)],1)],1),_c('v-row',{staticClass:"mb-6",attrs:{"no-gutters":"","padless":""}},[_c('v-col',{staticClass:"mr-2"},[_c('v-card',[_c('v-card-title',[_c('h4',[_vm._v("国内")])]),(_vm.domesticDataList)?_c('v-data-table',{attrs:{"disable-pagination":"","headers":_vm.headers,"items":_vm.domesticDataList,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.url))])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('img',{directives:[{name:"show",rawName:"v-show",value:(item.img_flg == 1),expression:"item.img_flg == 1"}],staticClass:"mt-2 mb-2",attrs:{"src":'img/com_' + item.id + '.png',"alt":item.name,"width":"100px"}})]}}],null,true)}):_vm._e()],1)],1),_c('v-col',[_c('v-card',[_c('v-card-title',[_c('h4',[_vm._v("海外")])]),(_vm.foreignDataList)?_c('v-data-table',{attrs:{"disable-pagination":"","headers":_vm.headers,"items":_vm.foreignDataList,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.url))])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('img',{directives:[{name:"show",rawName:"v-show",value:(item.img_flg == 1),expression:"item.img_flg == 1"}],staticClass:"mt-2 mb-2",attrs:{"src":'img/com_' + item.id + '.png',"alt":item.name,"width":"100px"}})]}}],null,true)}):_vm._e(),_c('v-card')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }